<template>
  <footer>
    <section class="footer-section">
      <div class="wrapper">
        <nav class="navigation">
          <div class="left-nav">
            <div
              v-for="{ title, children } in footerItems"
              :key="title"
              class="column"
            >
              <h2 class="header">{{ title }}</h2>
              <NuxtI18nLink
                v-for="{ title: name, link, isNewWindow, icon } in children"
                :key="name"
                class="link"
                :data-t="`link-${link.split('/').at(-1)}-7m5r`"
                :to="link"
                :target="isNewWindow ? '_blank' : ''"
              >
                <div class="link-wrapper">
                  <StIcon v-if="icon" :name="icon" size="16" />
                  <span>{{ name }}</span>
                </div>
              </NuxtI18nLink>
            </div>
          </div>

          <div class="right-nav">
            <div class="top-content">
              <h2 class="age">18+</h2>
              <LicenseValidator v-if="isVisibleLicenceValidator" />
            </div>
            <div class="social-media">
              <NuxtI18nLink
                v-for="(link, name) in socialMedia"
                :key="name"
                :to="link.path"
                class="bottom-link"
                target="_blank"
              >
                <StIcon :name="link.icon" />
              </NuxtI18nLink>
            </div>
            <LanguageSelector />
          </div>
        </nav>
      </div>
    </section>
    <article v-if="copyright" class="copyright">
      <NuxtI18nLink to="/" class="logo" target="_blank">
        <img src="./images/logo-mini.svg" alt="logotype" />
      </NuxtI18nLink>
      <p class="info">
        {{ copyright }}
      </p>
    </article>
  </footer>
</template>

<script setup lang="ts">
import LanguageSelector from './parts/LanguageSelector.vue'
import { useFooter } from './useFooter'
import LicenseValidator from './parts/LicenseValidator.vue'

const { socialMedia, footerItems, copyright, isVisibleLicenceValidator } =
  useFooter()
</script>

<style scoped>
.footer-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-050);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  align-items: center;
  align-self: stretch;

  width: 100%;
  padding: var(--spacing-300) var(--spacing-200);

  background: var(--background-primary);
  border-radius: var(--border-radius-150);
}

.logo {
  width: var(--spacing-600);
}

.navigation {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
}

.left-nav {
  display: flex;
  gap: var(--spacing-600);
  align-items: flex-start;
}

.right-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  justify-content: space-between;
}

.header {
  margin: 0;
  font: var(--desktop-text-md-semibold);
}

.column {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
  align-items: flex-start;
}

.link {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
  text-decoration: none;

  &:hover {
    color: var(--text-link);
  }

  &.active {
    color: var(--text-link);
  }
}

.link-wrapper {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.age {
  font: var(--desktop-text-lg-medium);
  color: var(--text-tertiary);
}

.top-content {
  display: flex;
  gap: var(--spacing-300);
  align-items: center;
  justify-content: center;
}

.social-media {
  display: flex;
  gap: var(--spacing-200);
  align-items: center;
}

.bottom-link {
  color: var(--icon-secondary);

  &:hover {
    color: var(--text-primary);
  }
}

.info {
  margin: 0;
}

.copyright {
  display: flex;
  gap: var(--spacing-200);
  align-items: center;
  align-self: stretch;

  margin-top: var(--spacing-050);
  padding: var(--spacing-300) var(--spacing-200);

  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);

  background: var(--background-primary);
  border-radius: var(--border-radius-150) var(--border-radius-150) 0 0;
}
</style>
